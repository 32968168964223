import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import GlobalStyle from './components/GlobalStyle';
import Header from './components/Header'; // Updated Header
import HeroSection from './components/HeroSection'; // Updated Hero Section
import Features from './components/Features'; // Updated Features
import ExamPortfolio from './components/ExamPortfolio'; // Updated Exam Portfolio
import OurClients from './components/OurClients';
import ContactForm from './components/ContactForm'; // Updated Contact Form
import Footer from './components/Footer';
import BackToTop from './components/BackToTop';

// Add a loading screen component
const LoadingScreen = () => {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(135deg, #001440, #002060)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <motion.div
          style={{
            fontSize: '42px',
            fontWeight: 'bold',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <img 
            src="/favicon.ico" 
            alt="CalC Logo" 
            style={{ width: '38px', height: '38px', marginRight: '10px' }} 
          />
          Cal<span style={{ color: '#4080ff' }}>C</span>
        </motion.div>

        <motion.div
          style={{
            width: '200px',
            height: '4px',
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '2px',
            overflow: 'hidden',
          }}
        >
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            transition={{ duration: 1, ease: 'easeInOut' }}
            style={{
              height: '100%',
              background: 'linear-gradient(90deg, #4080ff, #2060cc)',
              borderRadius: '2px',
            }}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

// Updated Footer component
const UpdatedFooter = () => {
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        backgroundColor: '#001430',
        padding: '60px 20px 30px',
        color: 'white',
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img 
            src="/favicon.ico" 
            alt="CalC Logo" 
            style={{ width: '24px', height: '24px', marginRight: '8px' }} 
          />
          Cal<span style={{ color: '#4080ff' }}>C</span>
        </div>
        
        <div
          style={{
            fontSize: '16px',
            color: 'rgba(255, 255, 255, 0.7)',
            marginBottom: '40px',
            textAlign: 'center',
            maxWidth: '600px',
          }}
        >
          Premium test questions for Digital SAT, AP Exams, and all major US standardized tests
        </div>
        
        <div
          style={{
            width: '100%',
            height: '1px',
            background: 'rgba(255, 255, 255, 0.1)',
            margin: '20px 0',
          }}
        />
        
        <div
          style={{
            fontSize: '14px',
            color: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          &copy; {new Date().getFullYear()} CalC USA. All rights reserved.
        </div>
      </div>
    </motion.footer>
  );
};

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <GlobalStyle />
      <AnimatePresence>
        {loading ? (
          <LoadingScreen key="loading" />
        ) : (
          <motion.div
            key="content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Header />
            <HeroSection />
            <Features />
            <ExamPortfolio />
            <OurClients />
            <ContactForm />
            <UpdatedFooter />
            <BackToTop />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default App;