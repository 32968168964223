import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { 
  FaPenFancy, 
  FaBook, 
  FaHandshake, 
  FaLightbulb, 
  FaRocket, 
  FaLaptop, 
  FaChartLine, 
  FaUserGraduate 
} from 'react-icons/fa';

// Styled Components
const FeaturesContainer = styled.section`
  background-color: #ffffff;
  padding: 120px 20px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: -10%;
    right: -10%;
    width: 500px;
    height: 500px;
    background: radial-gradient(circle, rgba(0, 32, 96, 0.05) 0%, rgba(0, 32, 96, 0) 70%);
    border-radius: 50%;
    z-index: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -10%;
    left: -10%;
    width: 500px;
    height: 500px;
    background: radial-gradient(circle, rgba(0, 32, 96, 0.05) 0%, rgba(0, 32, 96, 0) 70%);
    border-radius: 50%;
    z-index: 0;
  }

  @media (max-width: 768px) {
    padding: 80px 20px;
  }

  @media (max-width: 480px) {
    padding: 60px 20px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const FeaturesHeader = styled.div`
  text-align: center;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

const Overline = styled(motion.span)`
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #4080ff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
`;

const FeaturesTitle = styled(motion.h2)`
  font-size: 42px;
  color: #002060;
  margin-bottom: 20px;
  font-weight: 800;
  line-height: 1.2;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

const FeaturesSubtitle = styled(motion.p)`
  font-size: 18px;
  color: #666666;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const FeatureItem = styled(motion.div)`
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 40px 30px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    border-color: #e0e0ff;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #4080ff, #80c0ff);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover:before {
    transform: scaleX(1);
  }

  @media (max-width: 480px) {
    padding: 30px 25px;
  }
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  background: linear-gradient(135deg, #f0f5ff, #e0e8ff);
  font-size: 28px;
  color: #4080ff;
  transition: all 0.3s ease;

  ${FeatureItem}:hover & {
    background: linear-gradient(135deg, #4080ff, #2060cc);
    color: #ffffff;
    transform: scale(1.1);
  }
`;

const FeatureNumber = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 48px;
  font-weight: 800;
  color: rgba(0, 32, 96, 0.05);
  line-height: 1;
  transition: all 0.3s ease;

  ${FeatureItem}:hover & {
    color: rgba(0, 32, 96, 0.1);
  }
`;

const FeatureTitle = styled.h3`
  font-size: 22px;
  color: #002060;
  margin-bottom: 15px;
  font-weight: 700;
`;

const FeatureDescription = styled.p`
  color: #666666;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  flex-grow: 1;
`;

const LearnMore = styled.a`
  display: inline-flex;
  align-items: center;
  color: #4080ff;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
  transition: all 0.3s ease;

  &:after {
    content: '→';
    margin-left: 8px;
    transition: transform 0.3s ease;
  }

  &:hover {
    color: #2060cc;
  }

  &:hover:after {
    transform: translateX(5px);
  }
`;

const CtaSection = styled(motion.div)`
  margin-top: 80px;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;

const CtaButton = styled(motion.a)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 35px;
  background: linear-gradient(90deg, #4080ff, #2060cc);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 32, 96, 0.2);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 8px 25px rgba(0, 32, 96, 0.3);
    transform: translateY(-3px);
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 12px 30px;
  }
`;

function Features() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const features = [
    {
      icon: <FaPenFancy />,
      title: 'Expert Content Creation',
      description:
        'Our specialists craft premium questions for Digital SAT and AP Exams with precise alignment to latest exam patterns and curriculum requirements.',
      link: '#contact'
    },
    {
      icon: <FaLaptop />,
      title: 'Digital Format Ready',
      description:
        'All questions are designed for modern digital testing interfaces, ensuring students are prepared for the new Digital SAT experience.',
      link: '#examportfolio'
    },
    {
      icon: <FaChartLine />,
      title: 'Data-Driven Approaches',
      description:
        'We analyze official test trends to ensure our questions mirror real exam difficulty levels and topic distribution.',
      link: '#contact'
    },
    {
      icon: <FaBook />,
      title: 'Comprehensive Coverage',
      description:
        'From SAT to AP Exams, we provide complete question banks covering all major US standardized tests and subjects.',
      link: '#examportfolio'
    },
    {
      icon: <FaHandshake />,
      title: 'Direct Collaboration',
      description:
        'Work directly with our content specialists to customize materials that perfectly match your teaching style and student needs.',
      link: '#contact'
    },
    {
      icon: <FaUserGraduate />,
      title: 'Academic Excellence',
      description:
        'Our questions are designed by educators with advanced degrees in their respective fields and test preparation expertise.',
      link: '#contact'
    },
    {
      icon: <FaRocket />,
      title: 'Fast Turnaround',
      description:
        'Need urgent content? Our efficient team delivers high-quality custom questions with industry-leading response times.',
      link: '#contact'
    },
    {
      icon: <FaLightbulb />,
      title: 'Innovative Solutions',
      description:
        'Stay ahead with our forward-thinking approach to test preparation and content development for modern examination formats.',
      link: '#contact'
    },
  ];

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6 }
    }
  };

  return (
    <FeaturesContainer id="features" ref={ref}>
      <ContentWrapper>
        <FeaturesHeader>
          <Overline
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            variants={itemVariants}
          >
            Our Specialties
          </Overline>
          <FeaturesTitle
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            variants={itemVariants}
          >
            Premium Test Preparation for Modern Exam Formats
          </FeaturesTitle>
          <FeaturesSubtitle
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            variants={itemVariants}
          >
            Expertly crafted questions for Digital SAT, AP Exams, and all major US standardized tests, designed to match official difficulty levels and newest exam formats.
          </FeaturesSubtitle>
        </FeaturesHeader>

        <FeaturesGrid
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate={controls}
        >
          {features.map((feature, index) => (
            <FeatureItem key={index} variants={itemVariants}>
              <FeatureNumber>0{index + 1}</FeatureNumber>
              <IconWrapper>{feature.icon}</IconWrapper>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
              <LearnMore href={feature.link}>Learn more</LearnMore>
            </FeatureItem>
          ))}
        </FeaturesGrid>

        <CtaSection
          initial={{ opacity: 0, y: 30 }}
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 30 },
            visible: { 
              opacity: 1, 
              y: 0,
              transition: { duration: 0.6, delay: 0.4 }
            }
          }}
        >
          <CtaButton 
            href="#contact"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Start Working With Us Today
          </CtaButton>
        </CtaSection>
      </ContentWrapper>
    </FeaturesContainer>
  );
}

export default Features;