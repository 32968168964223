import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { 
  FiUser, 
  FiMail, 
  FiEdit2, 
  FiMessageCircle, 
  FiCheckCircle, 
  FiAlertCircle,
  FiArrowRight,
  FiPhone,
  FiSettings
} from 'react-icons/fi';

const ContactContainer = styled.section`
  background: linear-gradient(135deg, #001440, #002060);
  padding: 120px 20px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 80px 20px;
  }

  @media (max-width: 480px) {
    padding: 60px 20px;
  }
`;

const ShapeTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23ffffff' fill-opacity='1' d='M0,224L80,234.7C160,245,320,267,480,250.7C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  z-index: 1;
`;

const PatternLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
  z-index: 0;
`;

const GlowCircle1 = styled.div`
  position: absolute;
  top: -150px;
  right: -150px;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 80, 255, 0.2) 0%, rgba(0, 32, 96, 0) 70%);
  z-index: 0;
`;

const GlowCircle2 = styled.div`
  position: absolute;
  bottom: -150px;
  left: -150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 80, 255, 0.2) 0%, rgba(0, 32, 96, 0) 70%);
  z-index: 0;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  color: white;

  @media (max-width: 992px) {
    text-align: center;
    max-width: 600px;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  max-width: 550px;

  @media (max-width: 992px) {
    width: 100%;
    max-width: 550px;
  }
`;

const ContactOverline = styled(motion.div)`
  font-size: 14px;
  font-weight: 600;
  color: #4080ff;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
`;

const ContactTitle = styled(motion.h2)`
  font-size: 42px;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 800;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const ContactSubtitle = styled(motion.p)`
  font-size: 18px;
  line-height: 1.6;
  color: #a0c0ff;
  margin-bottom: 30px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const ContactFeatures = styled(motion.div)`
  margin-bottom: 40px;
`;

const FeatureItem = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const FeatureIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: rgba(64, 128, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  color: #4080ff;
  font-size: 20px;
  flex-shrink: 0;
`;

const FeatureText = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h4`
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 5px;
`;

const FeatureDescription = styled.p`
  font-size: 15px;
  color: #a0c0ff;
  line-height: 1.5;
`;

const FormCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

  @media (max-width: 480px) {
    padding: 30px 20px;
  }
`;

const FormHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const FormTitle = styled.h3`
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: 700;
`;

const FormSubtitle = styled.p`
  font-size: 15px;
  color: #a0c0ff;
`;

const Form = styled.form`
  width: 100%;
`;

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 25px;
`;

const InputIcon = styled.span`
  position: absolute;
  top: 16px;
  left: 15px;
  color: ${({ isError, isFocused }) => 
    isError ? '#ff4d4d' : isFocused ? '#4080ff' : 'rgba(255, 255, 255, 0.5)'};
  transition: color 0.3s;
  z-index: 1;
`;

const FloatingLabel = styled.label`
  position: absolute;
  top: ${({ isFocused }) => (isFocused ? '5px' : '16px')};
  left: 45px;
  font-size: ${({ isFocused }) => (isFocused ? '12px' : '15px')};
  color: ${({ isError, isFocused }) => 
    isError ? '#ff4d4d' : isFocused ? '#4080ff' : 'rgba(255, 255, 255, 0.7)'};
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 1;
  background: ${({ isFocused }) => isFocused ? 'rgba(0, 20, 48, 0.9)' : 'transparent'};
  padding: ${({ isFocused }) => isFocused ? '0 5px' : '0'};
`;

const InputBase = styled.input`
  width: 100%;
  padding: 15px 15px 15px 45px;
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid ${({ isError, isFocused }) => 
    isError ? '#ff4d4d' : isFocused ? '#4080ff' : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 8px;
  color: white;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.3s;

  &:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 3px rgba(64, 128, 255, 0.2);
  }

  &::placeholder {
    color: transparent;
  }
`;

const Input = styled(InputBase)``;

const TextArea = styled(InputBase).attrs({ as: 'textarea' })`
  height: 120px;
  resize: vertical;
`;

const ErrorMessage = styled.span`
  color: #ff4d4d;
  font-size: 12px;
  position: absolute;
  bottom: -18px;
  left: 15px;
`;

const SelectWrapper = styled.div`
  position: relative;
`;

const Select = styled.select`
  width: 100%;
  padding: 15px 15px 15px 45px;
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid ${({ isError, isFocused }) => 
    isError ? '#ff4d4d' : isFocused ? '#4080ff' : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 8px;
  color: white;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s;

  &:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 3px rgba(64, 128, 255, 0.2);
  }
  
  option {
    background: #001430;
    color: white;
  }
`;

const SelectArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  color: rgba(255, 255, 255, 0.5);
`;

const SubmitButton = styled(motion.button)`
  width: 100%;
  padding: 15px;
  background: linear-gradient(90deg, #4080ff, #2060cc);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin-top: 15px;
  box-shadow: 0 5px 15px rgba(0, 32, 96, 0.3);

  svg {
    margin-left: 8px;
    transition: transform 0.3s;
  }

  &:hover svg {
    transform: translateX(5px);
  }

  &:hover {
    box-shadow: 0 8px 25px rgba(0, 32, 96, 0.4);
  }
`;

const StatusMessage = styled(motion.div)`
  margin-top: 20px;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: ${({ error }) => (error ? 'rgba(255, 77, 77, 0.1)' : 'rgba(39, 174, 96, 0.1)')};
  border: 1px solid ${({ error }) => (error ? 'rgba(255, 77, 77, 0.3)' : 'rgba(39, 174, 96, 0.3)')};
`;

const StatusIcon = styled.span`
  margin-right: 10px;
  font-size: 20px;
  color: ${({ error }) => (error ? '#ff4d4d' : '#27ae60')};
`;

const StatusText = styled.p`
  font-size: 14px;
  color: ${({ error }) => (error ? '#ff4d4d' : '#27ae60')};
  flex: 1;
`;

function ContactForm() {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');
  const [error, setError] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  // State for input focus and validation
  const [focusStates, setFocusStates] = useState({
    name: false,
    email: false,
    subject: false,
    service: false,
    message: false,
  });

  const [errors, setErrors] = useState({});

  const handleFocus = (field) => {
    setFocusStates({ ...focusStates, [field]: true });
  };

  const handleBlur = (field, value) => {
    setFocusStates({ ...focusStates, [field]: value !== '' });
  };

  const validateForm = () => {
    const newErrors = {};
    const formElements = form.current.elements;

    if (!formElements.user_name.value.trim()) {
      newErrors.user_name = 'Please enter your name';
    }

    if (!formElements.user_email.value.trim()) {
      newErrors.user_email = 'Please enter your email';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formElements.user_email.value)
    ) {
      newErrors.user_email = 'Please enter a valid email address';
    }

    if (!formElements.subject.value.trim()) {
      newErrors.subject = 'Please enter a subject';
    }

    if (!formElements.service.value) {
      newErrors.service = 'Please select a service';
    }

    if (!formElements.message.value.trim()) {
      newErrors.message = 'Please enter your message';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setStatusMessage('Please fix the errors in the form.');
      setError(true);
      return;
    }

    setStatusMessage('Sending your message...');
    setError(false);

    emailjs
      .sendForm(
        'service_7t17uqt', 
        'template_68392eu', 
        form.current,
        'GqMHmB3cjp0zue_rw' 
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatusMessage('Your message has been sent successfully! We will get back to you soon.');
          setError(false);
          form.current.reset();
          setFocusStates({
            name: false,
            email: false,
            subject: false,
            service: false,
            message: false,
          });
          setErrors({});
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(
            'An error occurred while sending your message. Please try again later.'
          );
          setError(true);
        }
      );
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6 }
    }
  };

  const features = [
    {
      icon: <FiSettings />,
      title: 'Custom Question Creation',
      description: 'Tailored to match Digital SAT and AP exam specifications.'
    },
    {
      icon: <FiCheckCircle />,
      title: 'Quality Assurance',
      description: 'Rigorous review process to ensure accuracy and relevance.'
    },
    {
      icon: <FiArrowRight />,
      title: 'Fast Turnaround',
      description: 'Quick delivery times for urgent content needs.'
    }
  ];

  return (
    <ContactContainer id="contact" ref={ref}>
      <ShapeTop />
      <PatternLayer />
      <GlowCircle1 />
      <GlowCircle2 />
      <ContentWrapper>
        <LeftColumn>
          <ContactOverline
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            variants={itemVariants}
          >
            Get in Touch
          </ContactOverline>
          <ContactTitle
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            variants={itemVariants}
          >
            Connect With Our Team of Elite Specialists
          </ContactTitle>
          <ContactSubtitle
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            variants={itemVariants}
          >
            Get direct access to our team of 70+ subject matter experts, including Math Olympiad winners and industry professionals who create world-class content for Digital SAT and AP Exams.
          </ContactSubtitle>
          
          <ContactFeatures
            as={motion.div}
            variants={containerVariants}
            initial="hidden"
            animate={controls}
          >
            {features.map((feature, index) => (
              <FeatureItem key={index} variants={itemVariants}>
                <FeatureIcon>{feature.icon}</FeatureIcon>
                <FeatureText>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </FeatureText>
              </FeatureItem>
            ))}
          </ContactFeatures>
        </LeftColumn>

        <RightColumn>
          <FormCard
            initial={{ opacity: 0, x: 30 }}
            animate={controls}
            variants={{
              hidden: { opacity: 0, x: 30 },
              visible: { 
                opacity: 1, 
                x: 0,
                transition: { duration: 0.6, delay: 0.3 }
              }
            }}
          >
            <FormHeader>
              <FormTitle>Contact Us Today</FormTitle>
              <FormSubtitle>Fill out the form below and we'll get back to you quickly</FormSubtitle>
            </FormHeader>

            <Form ref={form} onSubmit={handleSubmit} noValidate>
              {/* Name Field */}
              <FormGroup>
                <InputIcon 
                  isError={errors.user_name} 
                  isFocused={focusStates.name}
                >
                  <FiUser />
                </InputIcon>
                <FloatingLabel 
                  isFocused={focusStates.name}
                  isError={errors.user_name}
                >
                  Full Name
                </FloatingLabel>
                <Input
                  type="text"
                  name="user_name"
                  onFocus={() => handleFocus('name')}
                  onBlur={(e) => handleBlur('name', e.target.value)}
                  isError={errors.user_name}
                  isFocused={focusStates.name}
                />
                {errors.user_name && (
                  <ErrorMessage>{errors.user_name}</ErrorMessage>
                )}
              </FormGroup>

              {/* Email Field */}
              <FormGroup>
                <InputIcon 
                  isError={errors.user_email}
                  isFocused={focusStates.email}
                >
                  <FiMail />
                </InputIcon>
                <FloatingLabel 
                  isFocused={focusStates.email}
                  isError={errors.user_email}
                >
                  Email Address
                </FloatingLabel>
                <Input
                  type="email"
                  name="user_email"
                  onFocus={() => handleFocus('email')}
                  onBlur={(e) => handleBlur('email', e.target.value)}
                  isError={errors.user_email}
                  isFocused={focusStates.email}
                />
                {errors.user_email && (
                  <ErrorMessage>{errors.user_email}</ErrorMessage>
                )}
              </FormGroup>

              {/* Service Field */}
              <FormGroup>
                <InputIcon 
                  isError={errors.service}
                  isFocused={focusStates.service}
                >
                  <FiSettings />
                </InputIcon>
                {/* <FloatingLabel 
                  isFocused={focusStates.service}
                  isError={errors.service}
                >
                  Service Needed
                </FloatingLabel> */}
                <SelectWrapper>
                  <Select
                    name="service"
                    onFocus={() => handleFocus('service')}
                    onBlur={(e) => handleBlur('service', e.target.value)}
                    onChange={(e) => {
                      if(e.target.value) {
                        handleFocus('service');
                      }
                    }}
                    isError={errors.service}
                    isFocused={focusStates.service}
                    defaultValue=""
                    style={{ color: focusStates.service ? 'white' : 'rgba(255, 255, 255, 0.5)' }}
                  >
                    <option value="" disabled>Select a service</option>
                    <option value="Digital SAT Questions">Digital SAT Questions</option>
                    <option value="AP Exam Questions">AP Exam Questions</option>
                    <option value="Custom Content">Custom Content</option>
                    <option value="Bulk Orders">Bulk Orders</option>
                    <option value="Other">Other</option>
                  </Select>
                  <SelectArrow>▼</SelectArrow>
                </SelectWrapper>
                {errors.service && (
                  <ErrorMessage>{errors.service}</ErrorMessage>
                )}
              </FormGroup>

              {/* Subject Field */}
              <FormGroup>
                <InputIcon 
                  isError={errors.subject}
                  isFocused={focusStates.subject}
                >
                  <FiEdit2 />
                </InputIcon>
                <FloatingLabel 
                  isFocused={focusStates.subject}
                  isError={errors.subject}
                >
                  Subject
                </FloatingLabel>
                <Input
                  type="text"
                  name="subject"
                  onFocus={() => handleFocus('subject')}
                  onBlur={(e) => handleBlur('subject', e.target.value)}
                  isError={errors.subject}
                  isFocused={focusStates.subject}
                />
                {errors.subject && (
                  <ErrorMessage>{errors.subject}</ErrorMessage>
                )}
              </FormGroup>

              {/* Message Field */}
              <FormGroup>
                <InputIcon 
                  isError={errors.message}
                  isFocused={focusStates.message}
                >
                  <FiMessageCircle />
                </InputIcon>
                <FloatingLabel 
                  isFocused={focusStates.message}
                  isError={errors.message}
                >
                  Message
                </FloatingLabel>
                <TextArea
                  name="message"
                  onFocus={() => handleFocus('message')}
                  onBlur={(e) => handleBlur('message', e.target.value)}
                  isError={errors.message}
                  isFocused={focusStates.message}
                />
                {errors.message && (
                  <ErrorMessage>{errors.message}</ErrorMessage>
                )}
              </FormGroup>

              <SubmitButton
                type="submit"
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
              >
                Send Message <FiArrowRight />
              </SubmitButton>

              {statusMessage && (
                <StatusMessage 
                  error={error}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <StatusIcon error={error}>
                    {error ? <FiAlertCircle /> : <FiCheckCircle />}
                  </StatusIcon>
                  <StatusText error={error}>{statusMessage}</StatusText>
                </StatusMessage>
              )}
            </Form>
          </FormCard>
        </RightColumn>
      </ContentWrapper>
    </ContactContainer>
  );
}

export default ContactForm;