import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaArrowRight, FaCheckCircle } from 'react-icons/fa';

// Styled Components
const HeroContainer = styled.section`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 32, 96, 0.9)), url('/images/header.png');
  background-size: cover;
  background-position: center;
  position: relative;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: 80vh;
  }
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(circle at top right, rgba(0, 80, 255, 0.3), transparent 60%);
  z-index: 1;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 80px;
  }
`;

const LeftContent = styled.div`
  max-width: 600px;

  @media (max-width: 992px) {
    max-width: 100%;
    margin-bottom: 40px;
  }
`;

const RightContent = styled(motion.div)`
  max-width: 450px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);

  @media (max-width: 992px) {
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: 480px) {
    padding: 30px;
  }
`;

const Badge = styled(motion.span)`
  display: inline-block;
  background: linear-gradient(90deg, #4080ff, #80c0ff);
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 6px 12px;
  }
`;

const HeroTitle = styled(motion.h1)`
  font-size: 56px;
  line-height: 1.2;
  margin-bottom: 20px;
  font-weight: 800;
  background: linear-gradient(to right, #ffffff, #a0c0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 42px;
  }

  @media (max-width: 480px) {
    font-size: 36px;
  }
`;

const HeroSubtitle = styled(motion.p)`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #d0e0ff;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const HighlightList = styled(motion.ul)`
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
`;

const HighlightItem = styled(motion.li)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 18px;
  color: #ffffff;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const HighlightIcon = styled.span`
  color: #4080ff;
  margin-right: 10px;
  flex-shrink: 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 992px) {
    justify-content: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const PrimaryButton = styled(motion.a)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background: linear-gradient(90deg, #4080ff, #2060cc);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 5px 15px rgba(0, 32, 96, 0.3);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 32, 96, 0.4);
  }

  svg {
    margin-left: 10px;
    transition: transform 0.3s;
  }

  &:hover svg {
    transform: translateX(5px);
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 12px 25px;
    width: 100%;
  }
`;

const SecondaryButton = styled(motion.a)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background: transparent;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 12px 25px;
    width: 100%;
  }
`;

const CardTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  color: #d0e0ff;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 10px;
    color: #4080ff;
    flex-shrink: 0;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const StatItem = styled.div`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 5px;
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #a0c0ff;
  font-weight: 500;
`;

function HeroSection() {
  return (
    <HeroContainer id="home">
      <HeroOverlay />
      <HeroContent>
        <LeftContent>
          <Badge
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            70+ subject matter experts across all test domains
          </Badge>
          <HeroTitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Premium Test Questions Crafted by Experts
          </HeroTitle>
          <HeroSubtitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            Direct access to high-quality content crafted by highly experienced SMEs for Digital SAT, AP Exams, and other standardized tests - no middlemen, just exceptional quality at unbeatable rates.
          </HeroSubtitle>
          <HighlightList
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <HighlightItem
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              <HighlightIcon><FaCheckCircle size={20} /></HighlightIcon>
              Specialized in Digital SAT, AP Exam, ACT, GRE, GMAT, and LSAT formats
            </HighlightItem>
            {/* <HighlightItem
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <HighlightIcon><FaCheckCircle size={20} /></HighlightIcon>
              Custom question creation with 99% accuracy
            </HighlightItem> */}
            <HighlightItem
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.7 }}
            >
              <HighlightIcon><FaCheckCircle size={20} /></HighlightIcon>
              Trusted by leading test prep providers
            </HighlightItem>
          </HighlightList>
          <ButtonGroup>
            <PrimaryButton
              href="#contact"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get Started <FaArrowRight />
            </PrimaryButton>
            <SecondaryButton
              href="#features"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Learn More
            </SecondaryButton>
          </ButtonGroup>
        </LeftContent>
        <RightContent
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          <CardTitle>Why Choose CalC?</CardTitle>
          <FeatureList>
            <FeatureItem>
              <FaCheckCircle size={16} />
              Specialized in Digital SAT, AP Exam, ACT, GRE, GMAT, and LSAT formats
            </FeatureItem>
            <FeatureItem>
              <FaCheckCircle size={16} />
              Questions designed to match official difficulty levels
            </FeatureItem>
            <FeatureItem>
              <FaCheckCircle size={16} />
              Fast turnaround times for custom requests
            </FeatureItem>
            <FeatureItem>
              <FaCheckCircle size={16} />
              Direct collaboration with content creators
            </FeatureItem>
            <FeatureItem>
              <FaCheckCircle size={16} />
              Comprehensive explanations for all solutions
            </FeatureItem>
            <FeatureItem>
              <FaCheckCircle size={16} />
              White-label options for educational platforms
            </FeatureItem>
          </FeatureList>
          <StatsContainer>
            <StatItem>
              <StatNumber>700K+</StatNumber>
              <StatLabel>Questions Created</StatLabel>
            </StatItem>
            <StatItem>
              <StatNumber>100%</StatNumber>
              <StatLabel>Client Satisfaction</StatLabel>
            </StatItem>
            <StatItem>
              <StatNumber>1h</StatNumber>
              <StatLabel>Avg. Response Time</StatLabel>
            </StatItem>
          </StatsContainer>
        </RightContent>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;