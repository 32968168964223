import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaGraduationCap, FaLaptop, FaChartLine, FaUniversity } from 'react-icons/fa';

// Styled Components
const PortfolioContainer = styled.section`
  background: linear-gradient(135deg, #001440, #002060);
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.5;
    z-index: 0;
  }

  @media (max-width: 768px) {
    padding: 80px 20px;
  }

  @media (max-width: 480px) {
    padding: 60px 20px;
  }
`;

const GlowingCircle = styled.div`
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0,80,255,0.2) 0%, rgba(0,32,96,0) 70%);
  z-index: 1;
  top: -100px;
  right: -100px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
  }
`;

const GlowingCircleBottom = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0,80,255,0.15) 0%, rgba(0,32,96,0) 70%);
  z-index: 1;
  bottom: -100px;
  left: -100px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

const PortfolioTitle = styled.h2`
  text-align: center;
  font-size: 42px;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const PortfolioSubtitle = styled.p`
  text-align: center;
  font-size: 20px;
  color: #a0c0ff;
  margin-bottom: 60px;
  max-width: 800px;
  line-height: 1.5;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

const CategoryHeading = styled.h3`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  font-weight: 600;
  z-index: 2;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 3px;
    background-color: #4080ff;
    margin: 10px auto 0;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const ExamsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  max-width: 1200px;
  width: 100%;
  position: relative;
  z-index: 2;
  margin-bottom: 60px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const ExamCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 30px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #4080ff, #80c0ff);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover:before {
    transform: scaleX(1);
  }

  @media (max-width: 480px) {
    padding: 25px;
  }
`;

const ExamIcon = styled.div`
  font-size: 36px;
  color: #4080ff;
  margin-bottom: 15px;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const ExamName = styled.h4`
  font-size: 20px;
  color: #ffffff;
  margin: 0;
  font-weight: 600;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ExamType = styled.span`
  font-size: 14px;
  color: #a0c0ff;
  margin-top: 8px;
  font-weight: 400;
  text-align: center;
`;

function ExamPortfolio() {
  const examCategories = [
    {
      category: "Standardized Tests",
      exams: [
        { name: 'SAT', type: 'College Admission' },
        { name: 'Digital SAT', type: 'Modern Format' },
        { name: 'ACT', type: 'College Admission' },
        { name: 'PSAT', type: 'Scholarship Qualifying' }
      ]
    },
    {
      category: "Advanced Placement",
      exams: [
        { name: 'AP Calculus', type: 'Mathematics' },
        { name: 'AP Physics', type: 'Science' },
        { name: 'AP Chemistry', type: 'Science' },
        { name: 'AP Biology', type: 'Science' },
        { name: 'AP Literature', type: 'Humanities' },
        // { name: 'AP History', type: 'Social Sciences' },
        // { name: 'AP Computer Science', type: 'Technology' },
        { name: 'AP Statistics', type: 'Mathematics' }
      ]
    },
    {
      category: "Graduate Exams",
      exams: [
        { name: 'GRE', type: 'Graduate School' },
        { name: 'GMAT', type: 'Business School' },
        { name: 'LSAT', type: 'Law School' },
        // { name: 'MCAT', type: 'Medical School' }
      ]
    }
  ];

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <PortfolioContainer id="examportfolio">
      <GlowingCircle />
      <GlowingCircleBottom />
      <PortfolioTitle>Comprehensive Exam Coverage</PortfolioTitle>
      <PortfolioSubtitle>
        From standardized tests to advanced placement exams, we provide expert-crafted questions 
        that align perfectly with the latest exam formats and curriculum requirements.
      </PortfolioSubtitle>
      
      {examCategories.map((category, categoryIndex) => (
        <React.Fragment key={categoryIndex}>
          <CategoryHeading>{category.category}</CategoryHeading>
          <ExamsGrid 
            as={motion.div}
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
          >
            {category.exams.map((exam, examIndex) => (
              <ExamCard
                key={examIndex}
                variants={itemVariants}
              >
                <ExamIcon>
                  {categoryIndex === 0 ? <FaLaptop /> : 
                   categoryIndex === 1 ? <FaGraduationCap /> : <FaUniversity />}
                </ExamIcon>
                <ExamName>{exam.name}</ExamName>
                <ExamType>{exam.type}</ExamType>
              </ExamCard>
            ))}
          </ExamsGrid>
        </React.Fragment>
      ))}
    </PortfolioContainer>
  );
}

export default ExamPortfolio;