import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes, FaGraduationCap } from 'react-icons/fa';

// Styled Components
const HeaderContainer = styled(motion.header)`
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: ${({ scrolled }) => (scrolled ? '15px 0' : '25px 0')};
  background: ${({ scrolled }) => 
    scrolled 
      ? 'rgba(0, 20, 60, 0.95)' 
      : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent)'
  };
  backdrop-filter: ${({ scrolled }) => (scrolled ? 'blur(10px)' : 'none')};
  box-shadow: ${({ scrolled }) => (scrolled ? '0 5px 20px rgba(0, 0, 0, 0.1)' : 'none')};
  transition: all 0.3s ease;
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LogoIcon = styled.div`
  color: #4080ff;
  font-size: 28px;
  margin-right: 10px;
`;

const LogoText = styled.h1`
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  
  span {
    color: #4080ff;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  margin: 0 15px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background: #4080ff;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #a0c0ff;
  }

  &:hover:after, &.active:after {
    width: 100%;
  }

  &.active {
    color: #a0c0ff;
  }
`;

const ContactButton = styled.a`
  background: linear-gradient(90deg, #4080ff, #2060cc);
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  margin-left: 15px;
  box-shadow: 0 4px 10px rgba(0, 32, 96, 0.2);
  
  &:hover {
    box-shadow: 0 6px 15px rgba(0, 32, 96, 0.3);
    transform: translateY(-2px);
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
  
  @media (max-width: 992px) {
    display: block;
  }
`;

const MobileMenu = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  max-width: 350px;
  height: 100vh;
  background: #001430;
  padding: 80px 30px 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: -10px 0 30px rgba(0, 0, 0, 0.2);
`;

const MobileNavLink = styled(Link)`
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  margin: 15px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover, &.active {
    color: #4080ff;
  }
`;

const MobileContactButton = styled.a`
  background: linear-gradient(90deg, #4080ff, #2060cc);
  color: white;
  padding: 12px 20px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  margin-top: 30px;
  transition: all 0.3s ease;
  
  &:hover {
    background: linear-gradient(90deg, #5090ff, #3070dd);
  }
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
`;

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const navItems = [
    { name: 'Home', to: 'home' },
    { name: 'Features', to: 'features' },
    { name: 'Exam Portfolio', to: 'examportfolio' },
    { name: 'Clients', to: 'clients' },
  ];

  const mobileMenuVariants = {
    closed: { x: '100%' },
    open: { x: 0 }
  };

  const overlayVariants = {
    closed: { opacity: 0 },
    open: { opacity: 1 }
  };

  return (
    <HeaderContainer 
      scrolled={scrolled}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <NavContainer>
        <LogoContainer onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <LogoIcon>
            <img src="/favicon.ico" alt="CalC Logo" style={{ width: '26px', height: '26px' }} />
          </LogoIcon>
          <LogoText>Cal<span>C</span></LogoText>
        </LogoContainer>

        <NavLinks>
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.to}
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
              activeClass="active"
            >
              {item.name}
            </NavLink>
          ))}
          <ContactButton href="#contact">Contact Us</ContactButton>
        </NavLinks>

        <MobileMenuButton onClick={toggleMobileMenu}>
          {mobileMenuOpen ? <FaTimes /> : <FaBars />}
        </MobileMenuButton>

        <AnimatePresence>
          {mobileMenuOpen && (
            <>
              <Overlay 
                initial="closed"
                animate="open"
                exit="closed"
                variants={overlayVariants}
                transition={{ duration: 0.3 }}
                onClick={closeMobileMenu}
              />
              <MobileMenu
                initial="closed"
                animate="open"
                exit="closed"
                variants={mobileMenuVariants}
                transition={{ duration: 0.3, type: 'tween' }}
              >
                {navItems.map((item, index) => (
                  <MobileNavLink
                    key={index}
                    to={item.to}
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-80}
                    activeClass="active"
                    onClick={closeMobileMenu}
                  >
                    {item.name}
                  </MobileNavLink>
                ))}
                <MobileContactButton href="#contact" onClick={closeMobileMenu}>
                  Contact Us
                </MobileContactButton>
              </MobileMenu>
            </>
          )}
        </AnimatePresence>
      </NavContainer>
    </HeaderContainer>
  );
}

export default Header;